import { EnergyMonitoringAppConfiguration } from "../configuration";

export const GENERAL_ENV: EnergyMonitoringAppConfiguration = { 
  googleTagManagerContainerId: "GTM-N2P8M2H",
  roleMyBkw: "ROLE_MYBKW",
  roleEnergyMonitoringImpersonate: "ROLE_ENERGY_MONITORING_IMPERSONATE",
  locale: "de-CH",
  dateAndTimeFormat: "YYYY-MM-DDTHH:mm:ss.SSS[Z]",
  dateOnlyFormat: "DD.MM.YYYY",
  zeroDigitsFormat: "1.0-0",
  twoDigitsFormat: "1.2-2",
  monthAndYearFormat: "MM.YYYY",
  yearFormat: "YYYY",
  monthFormat: "MMMM",
  dailyFormat: "DD. MMMM YYYY",
  fullDateFormat: "DD. MMMM YYYY <br>HH:mm",
  endQuarterHourFormat: "HH:mm",
};
