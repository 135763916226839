import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { BfcAuthenticationService } from "@bfl/components/authentication";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { EnergyMonitoringService } from "../../service/energy-monitoring.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { MeteringPointsInformationDto } from "../../generated/energy-monitoring/model/meteringPointsInformationDto";
import { AdminService } from "../../service/admin.service";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private bfcAuthenticationService: BfcAuthenticationService,
    private router: Router,
    private configService: BfcConfigurationService,
    private energyMonitoringService: EnergyMonitoringService,
    private adminService: AdminService) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const hasMYBKWRole: boolean =
      this.bfcAuthenticationService.hasRealmRole(this.configService.configuration.roleMyBkw);

    return this.energyMonitoringService.getMeteringPoints().pipe(
      map((meteringPointsInfos: MeteringPointsInformationDto[]) => {
        if (this.adminService.isImpersonated()) {
          return true;
        }

        if (!hasMYBKWRole) {
          this.router.navigate(["unauthorized"]);
          return false;
        }

        if (this.isReportPage()) {
          const currentMeteringPoint: string = route.queryParamMap.get("meteringPointCode");
          const meteringPoints: string[] = meteringPointsInfos.map(meteringPointInfo =>
            meteringPointInfo.meteringPointCode);
          if (meteringPoints.includes(currentMeteringPoint)) {
            return true;
          }
        }

        this.router.navigate(["unauthorized"]);
        return false;
      }),
    );
  }

  isReportPage(): boolean {
    return window.location.href.includes("/report");
  }
}
