import { Injectable } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { EnergyMonitoringService } from "../../service/energy-monitoring.service";
import { map } from "rxjs/operators";
import { MeteringPointsInformationDto } from "../../generated/energy-monitoring/model/meteringPointsInformationDto";

@Injectable({
  providedIn: "root",
})
export class MeterSelectionGuard implements CanActivate {

  constructor(private router: Router, private energyMonitoringService: EnergyMonitoringService ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.energyMonitoringService.getMeteringPoints(true).pipe(
      map((meteringPointsInfos: MeteringPointsInformationDto[]) => {
        if (meteringPointsInfos.length > 1) {
          return true;
        } else {
          const meteringPoints: string[] = meteringPointsInfos.map(meteringPointInfo =>
            meteringPointInfo.meteringPointCode);
          const meteringPointCode = meteringPoints[0] === undefined ? "" : meteringPoints[0];
          const url: string = `/report/15minutes?meteringPointCode=${meteringPointCode}`;
          return this.router.parseUrl(url);
        }
      }),
    );
  }
  
}
