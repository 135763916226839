import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { BfcAuthenticationService } from "@bfl/components/authentication";

@Injectable({
  providedIn: "root",
})
export class AdminAuthGuard implements CanActivate {

  constructor(private bfcAuthenticationService: BfcAuthenticationService) {
  }

  canActivate(): boolean {
    return this.bfcAuthenticationService.hasRealmRole("ROLE_ENERGY_MONITORING_IMPERSONATE");
  }
}
