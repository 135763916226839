import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./core/service/auth-guard.service";
import { translations } from "./config/translations/translations";
import { MeterSelectionGuard } from "./meter-selection/meter-selection-guard/meter-selection.guard";
import { AdminAuthGuard } from "./admin-overview/admin-auth-guard/admin-auth.guard";

const routes: Routes = [
  {
    path: "admin",
    canActivate: [AdminAuthGuard],
    loadChildren: () => import("./admin-overview/admin-overview.module").then(m => m.AdminOverviewModule),
  },
  {
    path: "selection",
    canActivate: [AuthGuard, MeterSelectionGuard],
    loadChildren: () => import("./meter-selection/meter-selection.module").then(m => m.MeterSelectionModule),
  },
  {
    path: "report",
    canActivate: [AuthGuard],
    loadChildren: () => import( "./overview/overview.module").then(m => m.OverviewModule),
  },
  {
    path: "unauthorized",
    loadChildren: () => import("@bfl/components/unauthorized").then(m => m.BfcUnauthorizedModule.asChild(translations)),
  },
  {
    path: "**",
    redirectTo: "unauthorized",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
