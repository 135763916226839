import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  BfcAuthenticationInterceptor,
  BfcAuthenticationModule,
  BFC_AUTHENTICATION_INTERCEPTOR_WHITELIST,
} from "@bfl/components/authentication";
import {
  BfcConfigurationModule,
  BFC_CONFIGURATIONS,
} from "@bfl/components/configuration";
import {
  BfcTranslationModule,
  BFC_TRANSLATIONS,
} from "@bfl/components/translation";
import { BfcBasePageLayoutModule } from "@bfl/components/base-page-layout";
import { BfcUnauthorizedModule } from "@bfl/components/unauthorized";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { authenticationInterceptorWhitelist } from "./config/authentication-interceptor-whitelist";
import { configuration } from "./config/configuration";
import { translations } from "./config/translations/translations";
import { CoreModule } from "./core/core.module";
import { CommonModule, DecimalPipe, registerLocaleData } from "@angular/common";
import { BfcLinkModule } from "@bfl/components/link";
import { BfcSinglePageLayoutLargeModule } from "@bfl/components/single-page-layout-large";
import { EnergyMonitoringService } from "./service/energy-monitoring.service";
import { ResponseHelperService } from "./service/response-helper.service";
import { DatePipe } from "@angular/common";
import { BfcButtonModule } from "@bfl/components/button";
import { GraphsLayoutService } from "./service/graph-layout.service";
import localeDeCH from "@angular/common/locales/de-CH";
import localeFrCH from "@angular/common/locales/fr-CH";
import { BfcDatepickerModule } from "@bfl/components/datepicker";
import { BfcFormModule } from "@bfl/components/form";
import { BfcInputModule } from "@bfl/components/input";
import { BfcFormFieldModule } from "@bfl/components/form-field";
import { BfcLoadingModule } from "@bfl/components/loading";

registerLocaleData(localeDeCH);
registerLocaleData(localeFrCH);

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    BfcTranslationModule,
    BfcConfigurationModule,
    BfcAuthenticationModule,
    BfcBasePageLayoutModule,
    BfcLinkModule,
    BfcSinglePageLayoutLargeModule,
    BfcUnauthorizedModule,
    HttpClientModule,
    BfcButtonModule,
    BfcDatepickerModule,
    BfcFormModule,
    BfcInputModule,
    BfcFormFieldModule,
    BfcLoadingModule,
  ],
  providers: [
    EnergyMonitoringService,
    ResponseHelperService,
    GraphsLayoutService,
    DatePipe,
    DecimalPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BfcAuthenticationInterceptor,
      multi: true,
    },
    {
      provide: BFC_AUTHENTICATION_INTERCEPTOR_WHITELIST,
      useValue: authenticationInterceptorWhitelist,
    },
    {
      provide: BFC_TRANSLATIONS,
      useValue: translations,
      multi: true,
    },
    {
      provide: BFC_CONFIGURATIONS,
      useValue: configuration,
      multi: true,
    },
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
