import { catchError, map, tap } from "rxjs/operators";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, forkJoin, Observable, of, throwError } from "rxjs";
import { ResponseHelperService } from "./response-helper.service";
import * as moment from "moment";
import { TimeSeriesCollection } from "../core/model/time-series-collection";
import { Values } from "../generated/energy-monitoring/model/values";
import { DataType } from "../core/model/data-type";
import { AdminService } from "./admin.service";
import { Moment } from "moment";
import { MeteringPointsInformationDto } from "../generated/energy-monitoring/model/meteringPointsInformationDto";
import { GlobalLoadingService } from "./global-loading.service";

@Injectable()
export class EnergyMonitoringService {

  private meteringPointsInfos$: BehaviorSubject<MeteringPointsInformationDto[]> =
  new BehaviorSubject<MeteringPointsInformationDto[]>(null);

  constructor(
    private httpClient: HttpClient,
    private configurationService: BfcConfigurationService,
    private responseHelperService: ResponseHelperService,
    private adminService: AdminService,
    private globalLoadingService: GlobalLoadingService,
  ) {
  }

  public getDataForChartFromIMV(resolution: string, startDate: string, endDate: string,
    compareStart: Moment, compareEnd: Moment, meteringPointInfo: MeteringPointsInformationDto)
    : Observable<Map<string, TimeSeriesCollection>> {

    if (!meteringPointInfo.meteringPointCode) {
      return of(null);
    }

    const dataType = meteringPointInfo.isConsumptionByCustomer ? DataType.CONSUMPTION : DataType.PRODUCTION;
    // ISMK sees production of a customer as consumption
    const backendDataType = meteringPointInfo.isConsumptionByCustomer ? DataType.PRODUCTION : DataType.CONSUMPTION;
    const observableParams = [];

    const invalidPeriod = moment(endDate).isBefore(moment(startDate));
    const invalidComparisonPeriod = !!compareStart && !!compareEnd && compareEnd.isBefore(compareStart);

    const httpParams: HttpParams =
        this.buildParams(resolution, startDate, endDate, backendDataType, meteringPointInfo.meteringPointCode);

    const requestMeteringData$: Observable<TimeSeriesCollection> = invalidPeriod ? of({ values: [] }) :
      this.prepareIMVRequest(httpParams);

    observableParams.push(requestMeteringData$);

    if (compareStart) {
      const httpParamsCompare: HttpParams = this.buildParams(
        resolution,
        compareStart.toISOString(),
        compareEnd.toISOString(),
        backendDataType,
        meteringPointInfo.meteringPointCode,
      );

      const requestMeteringDataCompare$: Observable<TimeSeriesCollection> = invalidComparisonPeriod ?
        of({ values: [] }) : this.prepareIMVRequest(httpParamsCompare);

      observableParams.push(requestMeteringDataCompare$);
    }

    return forkJoin([...observableParams])
      .pipe(
        map(([meteringData, meteringDataCompare]) => {
          const tsMap = new Map<string, TimeSeriesCollection>([
            [dataType, meteringData],
          ]);

          if (compareStart) {
            tsMap.set("Comp:" + dataType, meteringDataCompare);
          }

          return tsMap;
        },
        ),
        catchError((error: unknown) => {
          this.responseHelperService.handleError(error, "SERVICE.SUBSCRIPTION.GENERAL.ERROR");
          return of(new Map<string, TimeSeriesCollection>([]));
        }),
      );
  }

  private prepareIMVRequest(httpParams: HttpParams): Observable<TimeSeriesCollection> {
    return this.httpClient.get(this.meteringDataUrl, { params: httpParams }).pipe(
      map((values: Values[]) => {
        return this.mapGraphData(values);
      }),
      catchError((error: unknown) => {
        this.responseHelperService.handleError(error, "SERVICE.SUBSCRIPTION.GENERAL.ERROR");
        return of({ values: [] });
      },
      ),
    );
  }

  public getMeteringPoints(isReloading: boolean = false): Observable<MeteringPointsInformationDto[]> {
    if (this.meteringPointsInfos$.getValue() !== null && !isReloading) {
      return this.meteringPointsInfos$.asObservable();
    }

    return this.httpClient.get<MeteringPointsInformationDto[]>(this.meteringPointsUrl).pipe(
      tap((meteringPoints: MeteringPointsInformationDto[]) => this.meteringPointsInfos$.next(meteringPoints)),
      tap(() => this.globalLoadingService.updateGlobalLoading(false)),
      catchError((error: unknown) => {
        this.responseHelperService.handleError(error, "SERVICE.SUBSCRIPTION.GENERAL.ERROR");
        return of([]);
      }),
    );
  }

  public getDataForCSV(
    startDate: string, endDate: string, meteringPointInfo: MeteringPointsInformationDto): Observable<Values[]> {
    // ISMK sees production of a customer as consumption
    const dataType = meteringPointInfo.isConsumptionByCustomer ? DataType.PRODUCTION : DataType.CONSUMPTION;
    const params = {
      "measurementValidityStart": startDate,
      "measurementValidityStop": endDate,
      "meteringPointCode": meteringPointInfo.meteringPointCode,
      "dataType": dataType,
    };

    return this.httpClient.get<Values[]>(this.dataCsvUrl, { params: params }).pipe(
      catchError((error: unknown) => {
        this.responseHelperService.handleError(error, "SERVICE.SUBSCRIPTION.GENERAL.ERROR");
        return throwError(error);
      }),
    );
  }

  private get baseUrl(): string {
    return this.configurationService.configuration.apiUrl;
  }

  private get meteringDataUrl(): string {
    if (this.adminService.isImpersonated()) {
      const gpNumber: number = this.adminService.getImpersonatedGPNumber();
      return `${this.baseUrl}/admin/metering-data?gpNumber=${gpNumber}`;
    }
    return this.baseUrl + "/metering-data";
  }

  private get meteringPointsUrl(): string {
    if (this.adminService.isImpersonated()) {
      const gpNumber: number = this.adminService.getImpersonatedGPNumber();
      return `${this.baseUrl}/admin/meteringpoint?gpNumber=${gpNumber}`;
    }
    return this.baseUrl + "/meteringpoint";
  }

  private get dataCsvUrl(): string {
    if (this.adminService.isImpersonated()) {
      const gpNumber: number = this.adminService.getImpersonatedGPNumber();
      return `${this.baseUrl}/admin/metering-data/csv?gpNumber=${gpNumber}`;
    }
    return this.baseUrl + "/metering-data/csv";
  }

  private buildParams(resolution?: string,
    startDate?: string,
    endDate?: string,
    dataType?: DataType,
    meteringPointCode?: string): HttpParams {
    let params = new HttpParams();

    if (!!resolution) {
      params = params.append("resolution", resolution);
    }

    if (!!startDate) {
      params = params.append("measurementValidityStart", startDate);
    }

    if (!!endDate) {
      params = params.append("measurementValidityStop", endDate);
    }

    if (!!dataType) {
      params = params.append("dataType", dataType);
    }

    if (!!meteringPointCode) {
      params = params.append("meteringPointCode", meteringPointCode);
    }

    return params;
  }

  private mapGraphData(values: Values[]): TimeSeriesCollection {
    const timeSeriesCollection: TimeSeriesCollection = {
      values: values,
      yAxisTitle: "kWh",
    };

    const measurements = values.map(v => v.v);
    timeSeriesCollection.yValueMin = Math.min(...measurements);
    timeSeriesCollection.yValueMax = Math.max(...measurements);

    // Modify fetched graphdata so that highcharts can render them
    if (!timeSeriesCollection.data || timeSeriesCollection.data.length === 0) {
      timeSeriesCollection.data = timeSeriesCollection.values?.map((vals) => {
        return [moment(vals.t).toDate().getTime(), vals.v ? vals.v : 0];
      });
    }

    return timeSeriesCollection;
  }

}
