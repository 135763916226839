import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { BfcAuthenticationService } from "@bfl/components/authentication";

@Injectable({
  providedIn: "root",
})
export class AdminService {

  private hasAdminRoleSubject$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private impersonatedGPNumberSubject$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  impersonatedGPNumber$: Observable<number> = this.impersonatedGPNumberSubject$.asObservable();

  constructor(private bfcAuthenticationService: BfcAuthenticationService) {
    this.hasAdminRoleSubject$.next(this.bfcAuthenticationService.hasRealmRole("ROLE_ENERGY_MONITORING_IMPERSONATE"));
  }

  private isAdmin(): boolean {
    return this.hasAdminRoleSubject$.getValue();
  }

  setImpersonatedGPNumber(gpNumber: number): void {
    if (this.isAdmin()) {
      this.impersonatedGPNumberSubject$.next(gpNumber);
    }
  }

  getImpersonatedGPNumber(): number {
    return this.impersonatedGPNumberSubject$.getValue();
  }

  disableImpersonation(): void {
    if (this.isAdmin()) {
      this.impersonatedGPNumberSubject$.next(null);
    }
  }

  isImpersonated(): boolean {
    if (this.isAdmin()) {
      return this.impersonatedGPNumberSubject$.getValue() !== null;
    }

    return false;
  }
}
