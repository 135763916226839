import { Injectable } from "@angular/core";
import { Moment } from "moment";
import { BfcConfigurationService } from "@bfl/components/configuration";

@Injectable()
export class DateService {

  constructor(private bfcConfigService: BfcConfigurationService) {
  }

  formatDate(date: Moment) : string {
    return date?.isValid() ? date.format(this.bfcConfigService.configuration.dateAndTimeFormat) : null;

  }

  formatDateOnly(date: Moment): string {
    return date?.isValid() ? date.format(this.bfcConfigService.configuration.dateOnlyFormat) : null;
  }

  formatMonthAndYear(date: Moment): string {
    return date?.isValid() ? date.format(this.bfcConfigService.configuration.monthAndYearFormat) : null;
  }

  formatYearOnly(date: Moment): string {
    return date?.isValid() ? date.format(this.bfcConfigService.configuration.yearFormat) : null;
  }

  isSameDate(date1: Moment, date2: Moment): boolean {
    return date1.isSame(date2);
  }

}