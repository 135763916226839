import { Component, HostBinding } from "@angular/core";
import { AdminService } from "./service/admin.service";
import { Observable } from "rxjs";
import { Title } from "@angular/platform-browser";
import { BfcTranslationService } from "@bfl/components/translation";
import { GlobalLoadingService } from "./service/global-loading.service";

@Component({
  selector: "app-component",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  @HostBinding("class")
  classes = "bfc-base";

  title = "energy-monitoring-webapp";

  impersonatedGPNumber$: Observable<number> = this.adminService.impersonatedGPNumber$;

  isGlobalLoading$: Observable<boolean> = this.globalLoadingService.isLoading$;

  constructor(
    private adminService: AdminService,
    private browserTitle: Title,
    private bfcTranslationService: BfcTranslationService,
    private globalLoadingService: GlobalLoadingService,
  ) {
    this.browserTitle.setTitle(this.bfcTranslationService.translate("BROWSER_TAB_TITLE"));
  }

}
