import { Injectable } from "@angular/core";
import { BfcTranslationService } from "@bfl/components/translation";
import { BfcNotificationService, BfcNotificationType } from "@bfl/components/notification";
import { Observable, throwError } from "rxjs";

@Injectable()
export class ResponseHelperService {
  constructor(
    private notificationService: BfcNotificationService,
    private translationService: BfcTranslationService,
  ) {}

  public handleError(error: any, messageKey: string): Observable<any> {
    this.notificationService.showNotification({
      type: BfcNotificationType.ERROR,
      message: this.translationService.translate(messageKey),
    });
    return throwError(error.message);
  }

  public handleSuccess(): void {
    this.notificationService.showNotification({
      type: BfcNotificationType.SUCCESS,
      message: this.translationService.translate("SERVICE.SUBSCRIPTION.SUBMIT.SUCCESS"),
    });
  }
}
