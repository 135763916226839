import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class GlobalLoadingService {

  private isLoadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  isLoading$: Observable<boolean> = this.isLoadingSubject$.asObservable();

  updateGlobalLoading(isLoading: boolean): void {
    this.isLoadingSubject$.next(isLoading);
  }
}
