<bfc-loading-overlay [loading]="isGlobalLoading$ | async">
    <bfc-single-page-layout-large>

        <div class="content">
            <div *ngIf="impersonatedGPNumber$ | async as impersonatedGPNumber">
                <p class="impersonation-text">{{ "ADMIN.IMPERSONATION_LABEL" | bfcTranslate }} {{ impersonatedGPNumber }}</p>
            </div>
            <router-outlet></router-outlet>
        </div>

    </bfc-single-page-layout-large>
</bfc-loading-overlay>